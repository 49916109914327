body {
    font-family: "Inter", sans-serif;
}


.custom-card {
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 11px;
}

.title {
    color: #71717A !important;
    font-size: 11px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;

}

.number {
    color: #7C3A84;

    /* Bold/21px */

    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 152.381% */
}

.top-forum {
    color: #7C3A84;

    /* Bold/16px */
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
}

.days {
    color: #606368;



    /* Semi-bold/11px */
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
}

.custom-span {
    color: #606368;

    /* Semi-bold/13px */
    font-family: "Plus Jakarta Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    /* 161.538% */
}

.custom-span2 {
    color: #606368;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* 131.25% */
    padding: 15px;
}

.custom-number {
    color: #7C3A84;
    text-align: right;

    /* Semi-bold/13px */
    font-family: "Plus Jakarta Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    /* 161.538% */
}

.custom-hr {
    margin-left: 20px;
    width: 80%;
}

.custom-card2 {
    padding: 10px;
    width: auto;
    height: 350px;

}

.custom-card3 {
    position: relative;
}

.custom-card3 span {
    margin-top: 15px;
    font-size: 1.2vw;
}


.top-drugs {
    color: #71717A;

    /* Bold/16px */
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
    padding: 10px;
}

.profile-name {
    color: #7C3A84;
    font-family: Inter;
    font-size: 19.059px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 20px;
}

.rivew {
    color: #8C8D90;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    height: 71px;
    flex-shrink: 0;
    align-self: stretch;
    /* 24.776px */
}



.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;


}

.forum-container span {
    margin-right: 10px;
    /* Adjust space between name and percentage */
}

.nav-title {
    color: #7C3A84;
    font-feature-settings: 'cv03' on, 'cv04' on;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    /* margin-left: 50px; */
    /* 36.25px */
}
.nav-title-2 {
    color: #7C3A84;
    font-feature-settings: 'cv03' on, 'cv04' on;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    /* margin-left: 50px; */
    /* 36.25px */
}

.signout {
    color: #7C3A84;
    text-align: center;
    font-feature-settings: 'cv01' on, 'cv03' on, 'cv04' on;

    /* Paragraph/Medium/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    /* 23.2px */
}

/* .top-forum {
    color: #7C3A84; */
    /* padding: 10px 20px; */
    /* font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
} */

.forum-container {
    color: #606368;
    padding: 10px 20px;
    /* Adjusted padding to match the title */
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: justify;
    /* Ensures text alignment within the container */
}

.custom-button {
    color: var(--Primary-700, #ad33079d);
    display: flex;
    cursor: pointer;
    height: 30px;
    padding: 0px 8px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #f4beab;
    width: fit-content;
    margin-top: 10px;
    border: none;
}

.yaxis-text {
    font-size: 1vh;
}

.my-button {
    cursor: pointer;
}

.filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    gap: 20px;
    margin-right: -10px;
    border: none;
}

.chart-title {
    color: #71717A !important;
    font-size: 1rem !important;
    font-style: normal !important;
    line-height: 15px !important;
    letter-spacing: 1px !important;
    font-weight: 500;
}

